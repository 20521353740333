var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.assemblies),function(assembly_i,index){return _c('div',{key:assembly_i,class:index === _vm.assemblies.length - 1 ? 'mt-5' : ''},[_c('span',[_vm._v(_vm._s(_vm.assemblyNames[assembly_i]))]),(_vm.progress[assembly_i])?_c('v-progress-linear',{staticClass:"my-5",attrs:{"indeterminate":""}}):_vm._e(),(
        _vm.responses &&
        _vm.responses[assembly_i] &&
        _vm.responses[assembly_i].mapped_description
      )?_c('v-expansion-panels',{attrs:{"multiple":"","flat":"","tile":""}},[(_vm.responses[assembly_i].ref_seq_differences)?_c('v-expansion-panel',{staticClass:"my-2"},[_c('v-expansion-panel-header',{staticClass:"overline blue--text text",staticStyle:{"background":"#e1f5fe"},attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-alert-circle ")])]},proxy:true}],null,true)},[_vm._v("There are differences between the mapped sequences ")]),_c('v-expansion-panel-content',{staticStyle:{"background":"#e1f5fe"}},[_c('span',{staticClass:"overline grey--text text"},[_vm._v("Reference Sequences Description")]),(_vm.responses[assembly_i].reference_sequences_description)?_c('Description',{attrs:{"description":_vm.responses[assembly_i].reference_sequences_description,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{
              descriptionRouter:
                _vm.responses[assembly_i].reference_sequences_description,
            },"tag":_vm.responses[assembly_i].tag}}):_vm._e(),_c('span',{staticClass:"overline grey--text text"},[_vm._v("Unfiltered Mapped Description")]),(_vm.responses[assembly_i].unfiltered_mapped_description)?_c('Description',{attrs:{"description":_vm.responses[assembly_i].unfiltered_mapped_description,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{
              descriptionRouter:
                _vm.responses[assembly_i].unfiltered_mapped_description,
            },"tag":_vm.responses[assembly_i].tag}}):_vm._e()],1)],1):_vm._e()],1):_vm._e(),(
        _vm.responses &&
        _vm.responses[assembly_i] &&
        _vm.responses[assembly_i].mapped_description
      )?_c('Description',{attrs:{"description":_vm.responses[assembly_i].mapped_description,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{
        descriptionRouter: _vm.responses[assembly_i].mapped_description,
      },"tag":_vm.responses[assembly_i].tag}}):_vm._e(),(
        _vm.responses &&
        _vm.responses[assembly_i] &&
        _vm.responses[assembly_i].genomic_description
      )?_c('Description',{attrs:{"description":_vm.responses[assembly_i].genomic_description,"css_class":'ok-description-link',"to_name":'Normalizer',"to_params":{
        descriptionRouter: _vm.responses[assembly_i].genomic_description,
      }}}):_vm._e(),(_vm.errors[assembly_i] && _vm.errors[assembly_i].errors)?_c('v-expansion-panels',{attrs:{"multiple":"","flat":"","tile":""}},[_c('v-expansion-panel',{staticClass:"my-2"},[_c('v-expansion-panel-header',{staticClass:"overline red--text text",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle ")])]},proxy:true}],null,true)},[_vm._v("Unsuccessful mapping ")]),_c('v-expansion-panel-content',_vm._l((_vm.errors[assembly_i].errors),function(error,index_errors){return _c('v-alert',{key:index_errors,staticClass:"mt-5",attrs:{"color":"red lighten-1","tile":"","border":"left","dark":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.getMessage(error))+" ")])])}),1)],1)],1):_vm._e(),(_vm.connectionErrors[assembly_i])?_c('v-alert',{attrs:{"prominent":"","type":"error","tile":"","elevation":"2","icon":"mdi-network-off-outline","color":"grey darken-4"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" "+_vm._s(_vm.connectionErrors[assembly_i].details)+" ")])],1)],1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }